.tablebodytext {
	display: none; }

.gamb-block {
	display: none; }

.preheader {
	font-size: 18px;
	padding: 0;

	a {
		color: $font-color; } }


header {
	background: $bg-gray;
	padding: 20px 0;

	.logo {
		img {
			float: left; }

		.description {
			float: left;
			padding-top: 12px;
			padding-left: 14px;
			font-size: 22px;
			line-height: 24px;
			max-width: 205px;
			font-weight: 700; } } }

nav {
	background: #ffffff; }


.main_ext_link__box {
	display: block;
	text-decoration: none;
	padding: 53px 0;
	text-align: center; }

.underlined_link {
	text-decoration: underline; }
.m_bold {
	font-weight: bold; }

.main_ext_link_item {
	display: inline-block;
	position: relative;
	margin: 0 5px;
	text-decoration: none;
	text-align: center;
	font-size: 12px;
	text-transform: uppercase;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	&:hover {
		-webkit-filter: none;
		filter: none;
		text-decoration: none; } }

footer {
	padding: 30px;
	background: $bg-gray;

	.logo {
		img {
			float: left; }

		.description {
			float: left;
			padding-top: 12px;
			padding-left: 20px;
			font-size: 24px;
			line-height: 24px;
			font-weight: 700; } } }


.page {
	max-width: 1920px;
	margin: 0 auto;
	position: relative;
	background: $bg-gray-light; }

.section {
	clear: left;
	width: 100%;
	overflow: hidden;
	padding: 20px 0; }

.content {
	padding-bottom: 50px; }

.content-contact {
	background: url('../img/contact/10.png') no-repeat center bottom;
	padding-bottom: 330px; }

.container-request {
	max-width: 807px;
	text-align: center;
	margin-bottom: 200px;
	h1 {
		text-align: center;
		margin-bottom: 20px!important;
		font-size: 44px;
		line-height: 44px; } }

.section-search-result {
	margin-top: 30px; }

.section-history {
	margin-bottom: 60px; }


.section-review-form {
	background: #ffffff;
	padding: 40px 0; }

.review_form {
	background: $bg-gray-light;
	padding: 20px 50px 80px;

	.button {
		float: right;
		clear: left; } }

.utp-section {
	background: #ffffff;
	padding-top: 40px; }

.about-info {
	text-align: justify;
	padding-right: 80px; }

.section-service {
	background: #ffffff;
	padding-top: 20px;
	padding-bottom: 50px; }

.section-branch {
	background: #fff;
	padding: 20px 0; }

.about-img {
	.lines {
		width: 292px;
		height: 144px;
		position: absolute;
		bottom: -20px;
		left: -50px; } }

.about_item {
	margin-bottom: 40px;
	text-align: center;
	overflow: hidden;
	a {
		text-decoration: none;
		color: #5d5d5d; }
	&:hover a {
		text-decoration: underline; } }
.about_item_name {
	margin-top: 20px; }

.section-person {
	background: #ffffff;
	padding: 40px 0; }

.section-mark {
	background: $bg-blue-light;
	color: #ffffff;
	text-align: justify;

	a {
		color: #ffffff; } }

.detail-text {
	padding-bottom: 40px; }

.page-event {
	padding-bottom: 40px; }

.text-block {
	padding-bottom: 30px;

	p:first-child {
		margin-top: 0; }

	table {
		border-collapse: collapse;
		border: 1px solid #aaa;

		td {
			padding: 5px 20px;
			font-size: 15px;
			font-weight: 700;
			border-right: 1px solid #aaa; }

		tr:hover {
			color: #ffffff;
			background: $bg-blue-light; }

		tr:first-child {
			border: 1px solid #aaa; } } }


.tab__content {
	margin-bottom: 60px; }


.section-hero {
	background: $bg-blue-light url('../img/index/6.png') no-repeat right center; }


.section-about {
	min-height: 670px;
	background: url('../img/index/17.png') no-repeat bottom center; }


.section-review {
	color: #fff;
	background: #184A88 url('../img/index/18.png') no-repeat top left;
	background-size: cover;

	.review-block {
		padding-top: 30px;
		padding-right: 30px;

		.h {
			margin-bottom: 25px; } }


	.contact-block {
		padding-top: 30px;
		padding-left: 50px;
		padding-bottom: 20px;
		background: #234e7e;

		.h {
			margin-bottom: 25px; } } }


.review_button {
	text-align: center;
	margin-top: 30px; }



.js-owl-poster {
	overflow: hidden;

	.owl-item {

		img {
			width: auto;
			max-width: 462px;
			height: auto;
			max-height: 504px;
			border-right: 50px solid $bg-blue;
			border-left: 50px solid $bg-blue; } }

	.owl-nav {
		position: absolute;
		bottom: 40px;
		right: 45%;
		width: 100px;

		.owl-prev, .owl-next {
			width: 40px;
			height: 40px;
			background-position: center center;
			background-repeat: no-repeat;

			&:hover {
				opacity: .8; } }

		.owl-prev {
			background-image: url('../img/index/9.png');
			float: left; }
		.owl-next {
			background-image: url('../img/index/10.png');
			float: right; } }

	.owl-dots {
		position: absolute;
		bottom: 200px;
		right: 350px;
		display: none;

		.owl-dot {
			display: inline-block;

			span {
				width: 18px;
				height: 18px;
				background-image: url('../img/index/11.png');
				background-position: center bottom;
				background-repeat: no-repeat;
				display: inline-block;
				margin: 0 2px; }

			&.active {
				span {
					background-image: url('../img/index/12.png'); } } } } }
