@import "vars";

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	.nav > li > a {
		padding: 16px 19px;
		font-size: 20px; }

	.search-input input[type=text] {
		width: 80%; }

	.version {
		padding-left: 0; }

	.vk {
		margin-left: 0; }

	.poster-index .info {
		padding: 20px 45px;

		.name {
			font-size: 32px; }

		.date {
			font-size: 22px; } }

	.js-owl-poster .owl-item img {
		max-width: 100%; } }


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.container {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px; }

	.gamb-block {
		display: block; }


	.h, h1, h2, .h2 {
		text-align: center; }

	.nav {
		display: none;

		> li {
			float: none;
			display: block;

			a {
				display: block;
				text-align: center; }

			&:last-child {
				a {
					padding-right: 28px; } }

			ul {
				width: 100%;
				position: relative;
				display: block;

				> li > a {
					border-bottom: 0; } } } }

	.news-index {
		.preview {
			min-height: auto; } }


	.review-block {
		padding-bottom: 40px; }

	.review-index {
		min-height: auto; }

	.js-owl-poster {
		margin-top: 10px;

		.owl-item {
			img {
				max-width: 100%;
				border-left: 10px solid #184A88;
				border-right: 10px solid #184A88; } }

		.owl-nav {
			bottom: auto;
			top: 160px;
			right: 0;
			width: 100%; } }

	.poster-index {

		.img {
			display: none; }

		.info {
			padding: 40px;
			text-align: center;

			.date {
				font-size: 16px; }

			.name {
				font-size: 22px; } } }

	.about-index {
		padding: 40px 20px;
		text-align: center;

		.preview {
			padding-right: 0; } }

	.section-about {
		background: transparent;
		min-height: auto; }

	.section-review {
		background-image: none;

		.review-block {
			padding-left: 30px;
			text-align: center; }

		.contact-block {
		 	padding: 30px; } }

	.review-index {
		margin-bottom: 20px; }

	.contact {
		.item {
			text-align: center;
			.info {
				margin: auto; }
			.ico {
				display: none; } } }


	header, footer {
		padding-left: 20px;
		padding-right: 20px;

		.feedback {
			padding-top: 10px;
			display: block; }

		.search-input {
			padding: 10px 20px;
			clear: left;
			overflow: hidden;
			margin-top: 0;


			input[type=text] {
				width: 80%; } } }

	footer {
		.logo {
			padding-bottom: 10px;
			overflow: hidden;

			.description {
				font-size: 18px;
				line-height: 21px;
				padding-top: 5px;
				width: 200px; } } }

	.map {
		text-align: center;

		img {
			max-width: 100%; } }

	.bx-breadcrumb {
		padding-left: 20px;
		padding-right: 20px; }


	.photo {
		height: auto; }

	.navigation {
		margin-bottom: 30px;
		margin-right: 20px;
		margin-left: 20px; }

	.text-block {
		padding-right: 20px;
		padding-left: 20px; }

	#catalogue {
		.modal-body {
			.description,
			.img {
				height: auto;
				text-align: center; }

			.price {
				margin-top: 20px;
				text-align: center; }

			.more {
				overflow: visible; }

			.name {
				img {
					margin-left: 0; } } } }


	.about-info,
	.utp {
		padding-left: 20px;
		padding-right: 20px; }

	.about-img {
		text-align: center; }

	.utp {
		.info {
			width: 80%; } }

	.branch .info {
		padding-left: 0;
		margin-top: 20px; }

	header .search-input input[type=text] {
		width: 72%; }

	.phone .tel {
		font-size: 20px; }

	header .logo .description {
		max-width: 130px;
		padding-top: 7px;
		font-size: 18px;
		line-height: 18px; } }


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

	header {
		.logo {
			width: 230px;
			margin: 0 auto;
			overflow: hidden; } }

	footer {
		text-align: center;
		.logo {
			width: 280px;
			margin: 0 auto;
			overflow: hidden;

			.description {
				text-align: left; } } }


	.version {
		padding: 10px 0;
		text-align: center;

		img {
			float: none; } }

	.vk {
		padding-left: 0;
		margin-left: 0;
		text-align: center;
		display: block;
		padding-top: 10px;
		padding-bottom: 10px;

		img {
			float: none; } }

	.phone {
		text-align: center;
		display: block;
		clear: left;
		float: none; }

	header .search-input {
		padding: 20px 0 10px;

		input[type=text] {
			width: 75%; } }

	.news {
		text-align: center;

		.img {
			margin-bottom: 20px; } }

	.history:nth-child(2) {
		.img {
			margin-top: 0; } }

	.history,
	.history:nth-child(even) {
		.img {
			float: none;
			text-align: center; } }

	.club {
		text-align: center;

		.img {
			margin-top: 20px; } }


	.js-owl-mark,
	.mark_img {
		width: 100% !important;
		height: auto !important; }

	.js-owl-mark .owl-item {
		img {
			width: 100% !important;
			height: auto !important; } }

	.service {
		table {
			td {
				&:nth-child(1) {
					width: 100px; }

				&:nth-child(2) {
					width: 120px;
					padding-left: 10px;
					font-size: 40px; }

				&:nth-child(3) {
					font-weight: 600;
					padding-left: 10px; } } } } }


/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

	#catalogue {
		.modal-body {
			.name {
				font-size: 28px; } } }

	.utp {
		text-align: center;

		.ico,
		.info {
			float: none; }

		.info {
			padding-left: 20px;
			padding-right: 20px;
			width: 100%; } }

	.person {
		height: auto;
		margin: 20px;
		.img {
			height: auto; } }

	.contact .item .info {
		width: auto; }

	.service {
		table {
			td {
				&:nth-child(1) {
					width: 85px;
					font-size: 14px; }

				&:nth-child(2) {
					width: 100px;
					padding-left: 10px;
					font-size: 23px; }

				&:nth-child(3) {
					font-weight: 600;
					padding-left: 10px;
					width: auto;
					font-size: 14px; } } } } }


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	.poster {
		height: 585px;
		.info {
			padding: 10px 20px;
			.arrow {
				margin-top: -30px; }
			.date {
				font-size: 18px; }
			.description {
				font-size: 13px;
				line-height: 14px; }
			.name {
				font-size: 19px; } }

		&:hover {
			.info {
				.description {
					height: 355px; }

				.arrow {
					margin-top: 0; } } } } }


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }

