/*! fancyBox v2.1.4 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	vertical-align: top;
}

.fancybox-wrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8020;
}

.fancybox-skin {
	position: relative;
	/*background: #f9f9f9;*/
	color: #444;
	text-shadow: none;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.fancybox-skin:hover .fancybox-title {
	display: block;
}

.fancybox-opened {
	z-index: 8030;
}

.fancybox-opened .fancybox-skin {
	/*-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);*/
	/*-moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);*/
	/*box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);*/
}

.fancybox-outer, .fancybox-inner {
	position: relative;
}

.fancybox-inner {
	overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
	-webkit-overflow-scrolling: touch;
}

.fancybox-error {
	color: #444;
	font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
	margin: 0;
	padding: 15px;
	white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
	display: block;
	width: 100%;
	height: 100%;
}

.fancybox-image {
	max-width: 100%;
	max-height: 100%;
}

#fancybox-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -22px;
	margin-left: -22px;
	background-position: 0 -108px;
	opacity: 0.8;
	cursor: pointer;
	z-index: 8060;
}

#fancybox-loading div {
	width: 44px;
	height: 44px;
	background: url('fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
	position: absolute;
	top: 0px;
	right: 0px;
	cursor: pointer;
	z-index: 9000;
	padding: 0 20px;
	width: 25px;
	height: 45px;
}

.fancybox-close:after {
	position: absolute;
	left: 0;
	top: 2px;
	color: #fff;
	content: "×";
	display: inline-block;
	font-family: tahoma, sans-serif;
	font-size: 33px;
	font-weight: normal;
	padding: 5px 0 0 3px;
	text-decoration: none;
	vertical-align: top;
}

.fancybox-close:hover:after {
	color: #2f8ed4;
}

.fancybox-title {
	background: rgba(24, 74, 136, 0.9);
	display: none;
	font-size: 14px !important;
	color: #fff;
	padding: 15px 20px;
	position: absolute !important;
	bottom: 0;
	width: 100%;
	box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.fancybox-nav {
	position: absolute;
	top: 0;
	width: 15%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
	background: transparent url('blank.gif'); /* helps IE */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	z-index: 8040;
}

.fancybox-prev {
	left: 0;
	background: url("../img/fancybox/fancy_arrow_prev.png") center no-repeat transparent;
	width: 140px;
}

.fancybox-prev:hover {
	background: url("../img/fancybox/fancy_arrow_prev_h.png") center no-repeat transparent;
}

.fancybox-next {
	right: 0;
	background: url("../img/fancybox/fancy_arrow_next.png") center no-repeat transparent;
	width: 140px;
}

.fancybox-next:hover {
	background: url("../img/fancybox/fancy_arrow_next_h.png") center no-repeat transparent;
}

.fancybox-nav span {
	/*position: absolute;*/
	/*top: 50%;*/
	/*width: 36px;*/
	/*height: 34px;*/
	/*margin-top: -18px;*/
	/*cursor: pointer;*/
	/*z-index: 8040;*/
	/*visibility: hidden;*/
}

.fancybox-prev span {
	/*left: 10px;*/
	/*background-position: 0 -36px;*/
}

.fancybox-next span {
	/*right: 10px;*/
	/*background-position: 0 -72px;*/
}

.fancybox-nav:hover span {
	visibility: visible;
}

.fancybox-tmp {
	position: absolute;
	top: -99999px;
	left: -99999px;
	visibility: hidden;
	max-width: 99999px;
	max-height: 99999px;
	overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
	overflow: hidden;
}

.fancybox-overlay {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: none;
	z-index: 8010;
	background-color: rgba(0, 0, 0, 0.65);
	background-image: url('fancybox_overlay.png');
}

.fancybox-overlay-fixed {
	position: fixed;
	bottom: 0;
	right: 0;
}

.fancybox-lock .fancybox-overlay {
	overflow: auto;
	overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
	visibility: hidden;
	font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
	position: relative;
	text-shadow: none;
	z-index: 8050;
}

.fancybox-opened .fancybox-title {
	visibility: visible;
}

.fancybox-title-float-wrap {
	position: absolute;
	bottom: 0;
	right: 50%;
	margin-bottom: -35px;
	z-index: 8050;
	text-align: center;
}

.fancybox-title-float-wrap .child {
	display: inline-block;
	margin-right: -100%;
	padding: 2px 20px;
	background: transparent; /* Fallback for web browsers that doesn't support RGBa */
	background: rgba(0, 0, 0, 0.8);
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	text-shadow: 0 1px 2px #222;
	color: #FFF;
	font-weight: bold;
	line-height: 24px;
	white-space: nowrap;
}

.fancybox-title-outside-wrap {
	position: relative;
	margin-top: 10px;
	color: #fff;
}

.fancybox-title-inside-wrap {
	padding-top: 10px;
}

.fancybox-title-over-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #fff;
	padding: 10px;
	background: #000;
	background: rgba(0, 0, 0, .8);
}

.fancybox-counter {
	color: #fff;
	position: absolute;
	right: 75px;
	top: 15px;
	font-size: 30px;
	font-family: 'PFDinTextCondPro', sans-serif;
	z-index: 10000;
}