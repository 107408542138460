@import "_mixins/font-face";

@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Thin/PFDinTextCondPro-Thin", 100);
@include font-face("PFDinTextCondPro", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Thin/PFDinTextCondPro-Thin", 100, 'italic');

@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Light/PFDinTextCondPro-Light", 300);
@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Light/PFDinTextCondPro-Light", 300, 'italic');

@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Regular/PFDinTextCondPro-Regular", 400);
@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Italic/PFDinTextCondPro-Italic", 400, 'italic');

@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Medium/PFDinTextCondPro-Medium", 500);
@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Medium/PFDinTextCondPro-Medium", 500, 'italic');

@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-Bold/PFDinTextCondPro-Bold", 600);
@include font-face("PFDinTextCondProg", "../fonts/PFDinTextCondPro/PFDinTextCondPro-BoldItal/PFDinTextCondPro-BoldItal", 600, 'italic');
