#fancybox-thumbs {
	position: fixed;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 8050;
    background: #000000;
    padding: 5px 0;
}

#fancybox-thumbs.bottom {
	bottom: 0;
}

#fancybox-thumbs.top {
	top: 2px;
}

#fancybox-thumbs ul {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;
}

#fancybox-thumbs ul li {
	float: left;
	opacity: 0.5;
    margin-right: 10px;
}

#fancybox-thumbs ul li.active {
	opacity: 1;
    transform: scale(1.2);
}

#fancybox-thumbs ul li:hover {
	opacity: 1;
}

#fancybox-thumbs ul li a {
	display: block;
	position: relative;
	overflow: hidden;
	outline: none;
}

#fancybox-thumbs ul li img {
	display: block;
	position: relative;
	border: 0;
	padding: 0;
}