*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

textarea {
	resize: none; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }

html, body {
	height: 100%; }

body {
	font-size: 17px;
	min-width: 320px;
	position: relative;
	line-height: 1.3;
	font-family: $default-font;
	font-weight: 400;
	color: $font-color;
	overflow-x: hidden;
	opacity: 1;
	letter-spacing: 1px; }
pre {
	display: block;
	position: relative;
	padding: 5px;
	border: 1px solid #333;
	border-radius: 5px;
	font-size: 12px; }

.h,h1 {
	font-size: 32px;
	letter-spacing: 2px;
	font-weight: 600;
	text-align: left;
	margin: 0 0 20px; }

.h_description {
	font-size: 22px;
	margin-bottom: 10px;
	margin-top: -20px;
	font-weight: 700; }

.h2 {
	color: $bg-blue2;
	font-size: 25px;
	margin-bottom: 15px;
	font-weight: 600;
	letter-spacing: 1px; }

.no-padding {
	padding-left: 0;
	padding-right: 0; }

.no-margin {
	margin-left: 0;
	margin-right: 0; }

.center {
	text-align: center;
	clear: left;
	float: none;
	width: 100%;
	display: block; }

.clear {
	clear: both; }


a.button,
input.button {
	display: inline-block;
	padding: 6px 22px;
	text-align: center;
	border-radius: 5px;
	border: 0;
	max-width: 100%;

	&:hover,
	&:focus {
		text-decoration: none;
		color: #777; }

	&_white {
		background: #ffffff;
		border: 1px solid #ffffff;
		color: $bg-blue-light;

		&:hover {
			background: $bg-blue-light;
			color: #ffffff; } }

	&_white-invert {
		background: $bg-blue-light;
		color: #ffffff;
		border: 2px solid #ffffff;

		&:hover {
			background: #ffffff;
			color: $bg-blue-light; } }


	&_contact {
		background: #ffffff;
		color: #555;
		padding: 3px 40px;
		font-size: 14px;
		margin-top: 10px;

		&:hover {
			background: #eeeeee;
			color: #333; } }

	&_contact2 {
		background: $bg-blue-light;
		color: #fff;
		padding: 3px 40px;
		font-size: 14px;
		margin-top: 10px;

		&:hover {
			background: $bg-blue;
			color: #fff; } }

	&_blue {
		color: #fff;
		background: #4A84C3;

		&:hover {
			color: #fff;
			background: #2c6cae; } }

	&_blue-light {
		color: #fff;
		background: $bg-blue-light;
		padding: 10px 50px;

		&:hover {
			color: #fff;
			background: #2c6cae; } } }

.copyright_box {
	padding: 15px 0 10px 0; }

.copyright_link {
	font-family: 'PFDinTextCondPro';
	font-size: 17px;
	color: #000;
	text-decoration: none;
	padding: 15px 0 10px 0;
	&:hover {
		text-decoration: none;
		color: #000; } }

.modal-header {
	text-align: center;
	font-size: 32px;
	font-weight: 600; }

.empty {
	clear: left;
	width: 100%;
	height: 10px !important; }

.blue_button {
	display: inline-block;
	outline: 0;
	background-color: #4a7ec9;
	background-position: 70% center;
	background-repeat: no-repeat;
	border: 1px solid #4a7ec9;
	font-size: 18px;
	padding: 7px 45px;
	border-radius: 3px;
	color: #fff;
	text-align: center;
	cursor: pointer;
	font-family: 'PFDinTextCondPro', sans-serif;
	&:hover {
		color: #fff;
		background-color: #2c6cae;
		text-decoration: none; } }
.blue_button.request_button {
	padding: 5px 40px; }

.mod-ta-lt {
	text-align: left; }
.mod-ta-rt {
	text-align: right; }
